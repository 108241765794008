<template>
  <div class="nft-constructor-page">
    <NftConstructor v-if="!isLoading" />
    <div v-else-if="isLoading" class="nft-constructor-page-loading">
      <img src="@/assets/img/spinner-clear.svg" class="loader" width="100">
      Loading Nft Constructor
    </div>
  </div>
</template>
<script>
import { computed, defineComponent, onUnmounted, ref, watch } from "vue-demi";
import NftConstructor from "@/components/NftConstructor/NftConstructor.vue";
import { useNftConstructorStore } from "@/store/nft-constructor";
import { usePageWithWallet } from "@/composables/usePageWithWallet";

export default defineComponent({
  components: { NftConstructor },
  props: {
  },
  setup(props) {

    const isLoading = ref(true)
    const store = useNftConstructorStore()
    usePageWithWallet({
      onInit() {
        isLoading.value = false
        store.fetchWalletTokens()
      }
    })
    return { isLoading }
  }

})
</script>
<style lang="scss">
.nft-constructor-page {
  &-loading {
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 22px;
    font-weight: bold;
    text-align: center;

    img {
      margin-bottom: 10px;
    }
  }

  @media screen and (max-width: 576px) {
    &-loading {
      padding: 15px;
      font-size: 18px;
    }
  }
}
</style>