<template>
  <div class="nft-constructor">
    <div class="nft-constructor-row">
      <div class="nft-constructor-content">
        <NftConstructorTitle />
        <div class="nft-constructor-content-elements">
         <NftConstructorCommunity v-model:tab="tab"/>

          <!-- <NftConstructorBack /> -->


          <!-- <NftConstructorEquippedTraits v-model:activeCategoryId="activeCategoryId" /> -->

          <NftConstructorTabs v-model:tab="tab" />

          <template v-if="tab === 'bg'">
            <NftConstructorTraits v-if="activeCategoryId" :key="activeCategoryId" :category-id="activeCategoryId" />
            <!-- 
              <NftConstructorCategories
                v-else
                v-model:value="activeCategoryId"
              /> -->
          </template>
          <NftConstructorMyTokens v-else-if="tab === 'my-tokens'" />
          <NftConstructorSaved v-else-if="tab === 'saved'" />
          <NftConstructorBgGallery v-else-if="tab === 'bg-gallery'" />

        </div>
      </div>
      <div class="nft-constructor-right">
        <NftConstructorPreview />
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, defineComponent, ref, watch } from "vue";
import NftConstructorMyTokens from "./NftConstructorMyTokens/NftConstructorMyTokens.vue";
import NftConstructorTabs from "./NftConstructorTabs/NftConstructorTabs.vue";
import NftConstructorPreview from "./NftConstructorPreview/NftConstructorPreview.vue";
// import NftConstructorEquippedTraits from "./NftConstructorEquippedTraits/NftConstructorEquippedTraits.vue";
import NftConstructorTitle from "./NftConstructorTitle.vue";
import NftConstructorTraits from "./NftConstructorTraits/NftConstructorTraits.vue";
import NftConstructorSaved from "./NftConstructorSaved/NftConstructorSaved.vue";
import NftConstructorCommunity from "./NftConstructorCommunity/NftConstructorCommunity.vue";
import { useRoute, useRouter } from "vue-router";
import NftConstructorBgGallery from "./NftConstructorBgGallery/NftConstructorBgGallery.vue";

const tab = ref('my-tokens')
const allowedTabs = ['my-tokens', 'bg', 'saved', 'bg-gallery', 'community']
const hashTab = useRoute().hash.slice(1)
console.log(hashTab)
if (allowedTabs.includes(hashTab)) {
  tab.value = hashTab
}
const router = useRouter()
const activeCategoryId = ref(13)
watch(tab, () => {
  router.replace({ hash: `#${tab.value}` })
})

</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&family=Oxanium:wght@700;800&display=swap");

.nft-constructor {
  font-family: var(--font-montserrat), sans-serif;
  font-weight: bold;
  background-color: var(--nft-constructor-bg);
  height: 100%;
  min-height: 100vh;

  &-row {
    display: flex;
    align-items: stretch;
    max-width: 1920px;
    margin: 0 auto;
  }

  

  &-content {
    position: relative;
    z-index: 2;
    min-width: 0;
    /*width: 830px; */
    width: 1200px;
    margin-right: 50px;

    &-elements {
      padding-left: 40px;
    }
  }

  &-right {
    min-width: 350px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;

    flex: 1;

    .nft-constructor-title-wrapper,
    .nft-constructor-navigation {
      display: none;
    }
  }

  &-shape-top {
    &-wrapper {
      position: absolute;
      pointer-events: none;
      z-index: 0;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      overflow: hidden;
    }

    position: absolute;
    top: -500px;
    left: 00%;
  }

  &-preview {}

  @media screen and (max-width: 1880px) {
    &-content {
      width: 830px;
    }
  }

  @media screen and (max-width: 950px) {
    &-row {
      flex-direction: column;
    }

    &-content {
      order: 2;
      width: 100%;
      margin-right: 0;
      margin-top: 50px;

      &-elements {
        padding: 0 15px;
      }

      .nft-constructor-title-wrapper {
        display: none;
      }

      .nft-constructor-navigation {
        display: none;
      }
    }

    &-right {
      order: 1;
      min-width: 0;
      flex-direction: column;

      .nft-constructor-title-wrapper {
        display: block;
      }

      .nft-constructor-navigation {
        display: block;
      }
    }

    &-shape-top {
      display: none;
    }
  }
}

.nft-constructor-toast-link-resolve {
  color: #fff !important;
  padding: 5px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  text-decoration: none;
  box-sizing: border-box;
  margin-top: 5px;
  display: inline-block;
  font-weight: bold;
  background-color: var(--primary);
  transition: 0.2s;

  &:hover {
    filter: brightness(0.8);
  }
}</style>