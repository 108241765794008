<template>
  <div class="nft-constructor-preview-wrapper">
    <div ref="previewEl" class="nft-constructor-preview">
      <div ref="previewCanvasWrapper" class="nft-constructor-preview-canvas-wrapper"
        :class="{ sticked: isCanvasSticked }">
        <div ref="previewCanvas" v-sound.click.excited class="nft-constructor-preview-canvas" @click.prevent="zoomImage">
          <div class="nft-constructor-preview-label-disclaimer" v-if="isPreview"
            v-tooltip="{ content: `You are seeing Preview Banner because you don't own the Traits you equipped to the Ape`, placement: 'bottom' }" />
        </div>
        <div v-if="isLoading" class="nft-constructor-preview-loading">
          <img src="@/assets/img/spinner-clear.svg" class="spinner" width="60" />
        </div>
        <div class="nft-constructor-preview-empty" v-if="isEmpty">
          Select Token
        </div>
        <IZoom v-if="!isLoading" width="20" class="nft-constructor-preview-zoom" @click.stop.prevent="zoomImage" />
      </div>
      <div v-if="isCanvasSticked" class="nft-constructor-preview-placeholder" />
      <a v-sound.click.excited href="#" class="nft-constructor-preview-share" :class="{ disabled: isLoading || isEmpty }"
        @click.prevent="downloadImage">
        Download
      </a>
    </div>

    <div class="nft-constructor-preview-items" v-if="false">
      <div class="nft-constructor-preview-items-row">
        <div class="nft-constructor-preview-item nft-constructor-preview-item-ape-id">
          <div class="nft-constructor-preview-item-name">BAP ID</div>
          <div class="nft-constructor-preview-item-value">#{{ apeOriginalId }}</div>
        </div>
        <div class="nft-constructor-preview-item nft-constructor-preview-item-rank">
          <div class="nft-constructor-preview-item-name">
            RANK
          </div>
          <div class="nft-constructor-preview-item-value">
            <span v-if="rank">{{ rank }}</span>
            <a v-if="!rank" class="nft-constructor-preview-item-rank-button" href="javascript:void(0)"
              @click.prevent="getRank" v-sound.click>Get Rank</a>
          </div>
        </div>

        <div class="nft-constructor-preview-item">
          <div class="nft-constructor-preview-item-name">TRAIT count</div>
          <div class="nft-constructor-preview-item-value">{{ traitCount }}</div>
        </div>
      </div>
      <div class="nft-constructor-preview-items-row">
        <div class="nft-constructor-preview-item">
          <div class="nft-constructor-preview-item-name">Body type</div>
          <div class="nft-constructor-preview-item-value">{{ bodyTypeName }}</div>
        </div>
        <div class="nft-constructor-preview-item">
          <div class="nft-constructor-preview-item-name">GUILD</div>
          <div class="nft-constructor-preview-item-value">{{ guild }}</div>
        </div>
        <div class="nft-constructor-preview-item">
          <div class="nft-constructor-preview-item-name">GENDER</div>
          <div class="nft-constructor-preview-item-value">{{ gender }}</div>
        </div>
      </div>
    </div>

    <div class="nft-constructor-preview-settings">
      <div class="nft-constructor-preview-settings-title">
        BG remove strength
      </div>
      <div class="nft-constructor-preview-settings-items">
        <button class="nft-constructor-preview-setting" :class="{ active: item - 1 === store.bgStrength }"
          v-for="item in 3" :key="item" @click.prevent="changeBgStrength(item - 1)">{{ item - 1 }}</button>
      </div>
    </div>
    <div class="nft-constructor-preview-action">

      <a v-sound.click.excited href="javascript:void(0)" class="nft-constructor-preview-button"
        :class="{ disabled: isMintDisabled }" @click.prevent="runMint">SAVE</a>
        <div class="nft-constructor-preview-tip" v-tooltip="{content:'We use the blockchain to pull in any image from the detected wallet. <br> Please do your own research if you are allowed to and in which ways the NFT image <br> can be modified/used outside of fun purposes on this site.', distance: 10, html: true}">
         <IInfo width="14"/> Please check IP terms of any NFT you use
        </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref, watch, toRef, computed, onUnmounted } from "vue";
import * as PIXI from 'pixi.js';
import { useNftConstructorStore } from "@/store/nft-constructor";
import { watchOnce } from "@vueuse/shared";
import ITwitter from "@/assets/icons/twitter.svg?inline"
import IInfo from "@/assets/img/info.svg?inline"
import IZoom from "@/assets/icons/zoom.svg?inline"
import IOpenSea from "@/assets/icons/opensea.svg?inline"
import { captureError } from "@/helpers/captureError";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { Config } from "@/config";
import { saveNftConstructorImage } from "@/helpers/nft-constructor/nft-constructor-save-image"

const loadImage = async (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => resolve(PIXI.Texture.from(img))
    img.onerror = reject
    img.src = src
  })
}
export default defineComponent({
  components: { IOpenSea, IInfo, ITwitter, IZoom },
  setup() {

    const store = useNftConstructorStore()
    const previewCanvas = ref(null)
    const cWidth = 800
    // const cWidth = 2048
    const cHeight = 800
    // const cHeight = 2048
    const app = new PIXI.Application({ height: cHeight, width: cWidth, backgroundAlpha: 0 });
    app.stage.sortableChildren = true;
    app.renderer.plugins.interaction.destroy()
    const isRendering = ref(false)
    const isLoading = computed(() => store.isLoading || isRendering.value)
    const isMinting = ref(false)
    const init = async () => {
      previewCanvas.value.appendChild(app.view)
    }

    onMounted(() => {
      init()
      renderTraits(1)
    })

    const renderId = ref(1)
    let toDestroy = []
    const renderTraits = async (rId) => {
      let currentTextures = []
      isRendering.value = true
      clearTraits()

      try {

        const resolvers = store.equippedTraitsImages.map(async image => {

          const texture = await loadImage(image.imageSrc)
          currentTextures.push(texture)

          const sprite = new PIXI.Sprite(texture);
          sprite.zIndex = image.zIndex;
          sprite.width = 800
          sprite.height = 800

          return sprite
        })
        const sprites = await Promise.all(resolvers)
        // To avoid race condition
        if (rId === renderId.value && app.stage) {
          sprites.forEach(sprite => app.stage.addChild(sprite))
        }

        toDestroy = [...toDestroy, ...sprites, ...currentTextures]

      } catch (err) {
        captureError(err)
      }
      setTimeout(() => {
        if (rId === renderId.value) {
          isRendering.value = false
        }
      }, 100)
    }
    const clearTraits = () => {
      app.stage.removeChildren()
      toDestroy.forEach(texture => {
        texture.destroy(true)
      })
      toDestroy = []
    }

    const onRender = async () => {
      renderId.value++
      await renderTraits(renderId.value)
    }
    store.ee.on('render', onRender)
    const isMintDisabled = computed(() => {
      if (isMinting.value) return true
      return false
    })




    const apeId = computed(() => store.ape.id)
    const rank = ref(null)
    watch(() => store.equippedTraits, () => {
      rank.value = null
    }, { deep: true })
    const getRank = async () => {
      const data = await useApesStorageApi({ toast: { error: true } }).exec({
        url: "/constructor/dynamic-rank",
        params: {
          currentTraitIds: store.equippedTraits.map(trait => trait.id),
          network: Config.network.name
        }
      })
      if (!data) return
      rank.value = data
    }
    const apeOriginalId = computed(() => {
      return store.originalId
    })
    const apeTokenId = computed(() => {
      return store.tokenId
    })
    const isSharing = ref(false)
    const shareTwitter = async () => {
      isSharing.value = true
      await shareApeTwitter()
      isSharing.value = false
    }
    const runMint = async () => {
      isMinting.value = true

      if (isLoading.value) {
        await new Promise(resolve => {
          watchOnce(isLoading, () => {
            if (!isLoading.value) resolve()
          })
        })
      }
      await saveImage()
      isMinting.value = false
    }
    const isEmpty = computed(() => {
      return !Object.keys(store.token).length && !store.equippedTraits.length
    })
    const saveImage = async () => {
      const image = await app.renderer.extract.base64(app.stage)
      const base64Response = await fetch(image);
      const imageBlob = await base64Response.blob();
      await saveNftConstructorImage(imageBlob)
    }
    const zoomImage = async () => {
      if (isLoading.value) {
        await new Promise(resolve => {
          watchOnce(isLoading, () => {
            if (!isLoading.value) resolve()
          })
        })
      }
      const image = await app.renderer.extract.base64(app.stage)
      useModal().open({ name: ModalName.ImageModal, props: { image } })
    }
    const changeBgStrength = (bgStrength) => {
      useNftConstructorStore().changeBgStrength(bgStrength)
    }
    const isCanvasSticked = ref(false)
    const canCanvasStick = ref(false)
    const checkResize = () => {
      if (window.innerWidth < 950) {
        canCanvasStick.value = true
      } else {
        canCanvasStick.value = false

      }
    }
    const downloadImage = async () => {
      const image = await app.renderer.plugins.extract.base64(app.stage)
      let link = document.createElement('a');
      link.download = 'image.png';
      link.href = image
      link.click();
    }

    const previewEl = ref()
    const previewCanvasWrapper = ref()
    const checkScroll = (e) => {
      // previewCanvas
      const elementPosition = previewEl.value.getBoundingClientRect()
      const offset = 80
      // const total =elementPosition.top + window.pageYOffset ;
      const total = elementPosition.top - offset + elementPosition.height;
      if (total < 0 && canCanvasStick.value) {
        isCanvasSticked.value = true
      } else {
        isCanvasSticked.value = false

      }
    }
    const isPreview = computed(() => false)
    onMounted(() => {
      window.addEventListener('resize', checkResize)
      window.addEventListener('scroll', checkScroll)
      checkResize()
      checkScroll()
    })
    onUnmounted(() => {
      window.removeEventListener('resize', checkResize)
      window.removeEventListener('scroll', checkScroll)
      store.ee.off('render', onRender)
      clearTraits()
      app.stage.destroy(true)
      app.destroy(true)
    })

    return {
      previewCanvas, downloadImage, store, isEmpty, changeBgStrength, Config, isSharing, previewEl, isPreview, zoomImage, renderId, isCanvasSticked, apeOriginalId, apeTokenId, apeId, runMint, isMintDisabled, isLoading,
    }
  }
})
</script>
<style lang="scss">
.nft-constructor-preview {
  position: relative;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  min-height: 0;

  &::before {
    content: "";
    width: 100%;
    position: absolute;
    height: 2px;
    background-color: var(--nft-constructor-gray);
    top: -8px;
    left: 0;
    right: 0;
  }

  &::after {
    content: "";
    width: 90%;
    position: absolute;
    height: 2px;
    background-color: var(--nft-constructor-gray);
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
  }

  &-wrapper {
    background: linear-gradient(180deg, #17111f 0%, #22080a 100%);
    padding: 30px;
    padding-top: 32px;
    position: sticky;
    width: auto;
    top: 80px;
    box-sizing: border-box;
    z-index: 20;
    max-width: 100%;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;

    &.no-ape-preview {
      .nft-constructor-preview-items {
        display: flex;

        >div {
          flex: 1;

          &:first-child {
            margin-right: 5px;
          }
        }
      }
    }
  }

  &-settings {
    margin-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid var(--border);

    &-title {
      text-transform: uppercase;
      opacity: 0.5;
      margin-bottom: 3px;
      font-size: 14px;
    }

    &-items {
      display: flex;
      border: 1px solid var(--border);
      border-radius: 5px;
    }
  }

  &-tip {
    margin-top: 10px;
    opacity: 0.5;
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: flex-start;
    svg {
      margin-right: 5px;
      margin-top: 2px;
    }
  }
  &-setting {
    flex: 1;
    padding: 4px;
    background: transparent;
    color: #fff;
    border: none;
    border-left: 1px solid var(--border);
    cursor: pointer;

    &:first-child {
      border-left: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:hover {
      background-color: var(--gray-light);
    }

    &.active {
      background-color: var(--primary);
    }
  }

  &-shape {
    margin-top: -18%;
    position: relative;
    z-index: 5;
    width: 100%;
  }

  &-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;

    img {
      max-width: 70%;
    }
  }

  &-empty {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(20px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    font-size: 18px;
    text-transform: uppercase;
  }

  &-zoom {
    position: absolute;
    right: 10px;
    bottom: 10px;
    background: var(--nft-constructor-gray);
    border-radius: 5px;
    cursor: pointer;
    padding: 5px;
    transition: 0.2s;
    opacity: 0;

    &:hover {
      filter: brightness(0.8);
    }
  }

  &-label-disclaimer {
    position: absolute;
    right: 0;
    top: 0;
    width: 25%;
    height: 25%;
    background-color: transparent;
    z-index: 10;
  }

  &-demo-banner {
    text-align: center;
    font-size: 18px;
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid var(--red);
    border-radius: 5px;
    text-transform: uppercase;
    color: var(--red);

  }

  &-canvas {
    font-size: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
    align-items: center;
    background: var(--nft-constructor-bg);
    border: 2px solid var(--nft-constructor-gray);
    cursor: pointer;
    position: relative;

    canvas {
      max-width: 100%;
      max-height: 100%;
    }

    &-wrapper {
      max-width: 100%;
      width: auto;
      position: relative;
      z-index: 1;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-direction: column;
      min-height: 0;

      &:hover {
        .nft-constructor-preview-zoom {
          opacity: 1;
        }
      }

      &.sticked {
        position: fixed;
        top: 80px;
        left: 50%;
        transform: translateX(-50%);
        width: 220px;
        z-index: 20;
      }
    }
  }

  &-items {
    text-align: center;
    font-family: var(--font-oxanium);
    text-transform: uppercase;
    position: relative;
    margin-top: 8px;

    &-row {
      display: flex;
    }
  }

  &-item {
    padding-bottom: 8px;
    padding-top: 8px;
    position: relative;
    border: 1px solid var(--nft-constructor-gray);
    flex: 1;
    margin-right: 5px;
    margin-bottom: 8px;
    border-radius: 5px;

    &:last-child {
      margin-right: 0;
    }

    &-name {
      font-size: 14px;
      color: #454b52;
      margin-bottom: 8px;
    }

    &-value {
      font-size: 16px;
    }

    &-ape-id &-value {}

    &-rank {
      &-button {
        color: var(--primary);
        padding: 4px 6px;
        border: 1px solid var(--primary);
        font-weight: normal;
        font-size: 12px;
        border-radius: 5px;
        text-decoration: none;
        line-height: 1.5;
        transition: 0.2s;

        &:hover {
          background-color: var(--primary);
          color: #fff;
        }
      }
    }

    &-rank &-value {
      font-size: 14px;
      color: var(--primary);
    }
  }

  &-actions {}

  &-action {
    margin-top: 10px;
  }

  &-button {
    height: 72px;
    width: 100%;
    background-color: var(--primary);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-oxanium);
    font-weight: 900;
    font-size: 24px;
    color: #fff;
    text-decoration: none;
    transition: 0.2s;

    &:hover {
      transform: scale(1.05);
    }

    &.disabled {
      pointer-events: none;
      background-color: #1e1e1e;

      &:hover {
        transform: none;
      }
    }

    &-opensea {
      background-color: var(--color-opensea);
    }
  }

  &-share {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--accent);
    padding: 8px;
    text-align: center;
    text-decoration: none;
    box-sizing: border-box;
    margin: 8px 0;
    border-radius: 5px;
    transition: 0.2s;

    &:hover {
      filter: brightness(0.8);
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }

    svg {
      margin-left: 5px;
    }
  }

  &-analyze {
    text-align: center;
    font-family: var(--font-oxanium);
    text-transform: uppercase;

    &-title {
      font-size: 18px;
      margin-bottom: 5px;
      color: var(--nft-constructor-accent);
    }

    &-text {
      font-size: 16px;
      text-transform: initial;
    }

    &-button {
      height: 52px;
      font-size: 16px;
      margin-top: 10px;
    }
  }

  &-placeholder {
    width: 100%;
    padding-bottom: 100%;
  }

  @media screen and (max-width: 1450px) {
    &-wrapper {
      max-width: 380px;
      height: calc(100vh - 80px);
    }

    &-item {
      &-name {
        font-size: 12px;
        margin-bottom: 5px;
      }
    }
  }

  @media screen and (max-height: 930px) {
    &-wrapper {
      height: calc(100vh - 80px);
    }
  }

  @media screen and (max-width: 950px) {
    &-wrapper {
      background: none;
      margin: 0 auto;
      padding: 15px;
      height: auto;
      max-width: 500px;
      width: 100%;
    }

    &-items {
      order: 1;
    }

    &-canvas {
      &-wrapper {
        width: auto;
      }
    }
  }

  @media screen and (max-width: 576px) {
    order: 2;

    &::before,
    &::after {
      display: none;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }

    &-canvas-wrapper {
      &.sticked {
        width: 150px;
      }
    }

    &-share {
      width: 150px;
      margin: 0 auto;
      margin-top: 8px;
    }

    &-button {
      height: 55px;

      &:hover {
        transform: none;
        filter: brightness(0.8);
      }
    }

    &-action {
      order: 3;
      margin-top: 20px;
    }
  }
}
</style>
