<template>
  <div class="nft-constructor-title-wrapper">
    <div class="nft-constructor-title">
      <a class="nft-constructor-title-back" href="javascript:void(0)"
        @click.prevent="$router.push({ name: 'Collection' })">
        <IArrowLeft width="8" />
      </a>
      <h2>My Collection</h2>
    </div>

  </div>
</template> 
<script>
import { defineComponent } from "vue-demi";

import IShape from "@/assets/icons/ape-constructor/ape-constructor-title-shape.svg?inline"
import IArrowLeft from "@/assets/icons/arrow-left.svg?inline"
import IVolume from "@/assets/icons/volume.svg?inline"
import { useAppSound } from "@/composables/useAppSound"
export default defineComponent({
  components: { IShape, IVolume,  IArrowLeft },
  setup() {

    return {
    }
  }
})
</script>
<style lang="scss">
.nft-constructor-title {
  display: flex;
  align-items: center;
  padding: 20px 40px;
  padding-right: 0;
  box-sizing: border-box;
  background: linear-gradient(90deg, #1c0a1b 30%, rgba(38, 13, 16, 0) 80%);
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  &-back {
    margin-right: 10px;
    color: #fff;
    border: 1px solid var(--border);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-light);
    transition: 0.2s;

    &:hover {
      background-color: transparent;
    }

    svg {}
  }

  &-formulas-button {
    margin-left: auto;

    &-mob {
      display: none;
    }
  }

  &-sound {
    position: relative;
    color: #fff;
    margin-left: 10px;
    border: 1px solid var(--border);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.2s;

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%) rotate(45deg);

      width: 0;
      height: 2px;
      border-radius: 5px;
      background-color: #fff;
      opacity: 0;
      width: 100%;
      transition: 0.2s;
    }

    &:hover {
      opacity: 0.8;
    }

    &.disabled {
      opacity: 0.6;

      &::before {
        opacity: 1;
      }
    }
  }

  h2 {
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 32px;
    margin: 0;
    padding: 0;
  }

  &-shape {
    margin-left: 40px;
  }

  &-wrapper {
    margin-bottom: 20px;
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    padding: 10px 20px;

    &-wrapper {
      margin-bottom: 15px;
    }

    h2 {
      font-size: 20px;
    }

    &-shape {
      display: none;
    }

    &-back {
      width: 22px;
      height: 22px;

      svg {
        width: 6px;
      }
    }
  }

  @media screen and (max-width: 500px) {
    &-formulas-button {
      display: none;

      &-mob {
        display: flex;
        margin-left: auto;
        width: 120px;
        padding: 5px 5px;
        font-size: 14px;

        .app-icon-link-formulas-button-new {
          width: 18px;
        }
      }
    }
  }
}</style>
