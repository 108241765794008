<template>
    <div v-sound.click class="nft-constructor-token" :class="{
        equipped: isEquipped,
        'is-nft': isNft
    }">
        <div v-if="isEquipped" class="nft-constructor-token-equipped nft-constructor-token-label">
            <ICheckmark width="14" />Equipped
        </div>


        <div class="nft-constructor-token-image">
            <img width="300" height="300" :src="image" alt="image">
        </div>
        <div class="nft-constructor-token-id">#{{ id }}</div>
        <div class="nft-constructor-token-name"> {{ name }}</div>



        <div v-if="$slots.actions" class="nft-constructor-token-actions">
            <slot name="actions" />
        </div>
    </div>
</template>
<script>
import { computed, defineComponent } from "vue-demi";
import ICheckmark from "@/assets/icons/ape-constructor/ape-constructor-checkmark.svg?inline";

import IOpenSea from "@/assets/icons/opensea.svg?inline"


import { Config } from "@/config";
import { resizeImageCF } from "@/helpers/resize-image-cloudflare";

export default defineComponent({
    components: { IOpenSea, ICheckmark },
    props: {
        item: {
            type: Object,
            default: () => ({}),
        },
        isEquipped: Boolean,
        isNft: Boolean
    },
    setup(props, { emit }) {
        const image = computed(() =>
            resizeImageCF(props.item.image, "256")
        );
        const name = computed(() =>
            props.item.name?.length > 30
                ? props.item.name?.slice(0, 30) + "..."
                : props.item.name
        );
        const id = computed(() => props.item.token_id);
        const OSLink = computed(() => 'https://opensea.io/' + props.item.address + '/' + props.item.id)




        return {
            name,
            image,
            OSLink,
            id,
        };
    },
});
</script>
<style lang="scss">
.nft-constructor-token {
    padding: 8px;
    padding-top: 32px;
    padding-bottom: 0;
    background-color: #0a0a0a;
    border-radius: 16px;
    box-sizing: border-box;
    text-align: center;
    color: #fff;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -2px;
        left: -2px;
        right: -2px;
        bottom: -2px;
        border-radius: 16px;
        z-index: -2;
        background: #121212;
    }

    &::after {
        content: "";
        position: absolute;
        bottom: -2px;
        left: -2px;
        right: -2px;
        height: 100%;
        z-index: -1;
        border-radius: 16px;
        background: linear-gradient(to top, var(--rarity-legendary), transparent);
    }

    &.common {
        &::after {
            background: linear-gradient(to top, var(--rarity-common), transparent);
        }
    }


    &-label {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 10px;
        left: 12px;

        svg {
            margin-right: 4px;
        }
    }

    &-equipped {
        color: var(--nft-constructor-accent);
    }

    &-image {
        margin-bottom: 8px;

        img {
            max-width: 100%;
            height: auto;
            border-radius: 10px;
        }
    }

    &-opensea {
        background-color: var(--color-opensea);
        flex-shrink: 0;
    }

    &-title {
        opacity: 0.5;
        text-align: left;
        text-transform: uppercase;
        font-size: 10px;
        margin-bottom: 4px;
    }

    &-lootbox {
        flex: 2;
        background-color: var(--primary);

        flex-shrink: 0;
        min-width: 90px;
    }



    &-name {
        font-size: 12px;
        text-transform: capitalize;
        display: block;
        padding-bottom: 8px;
        overflow: hidden;
        max-height: 50px;
    }

    &-id {
        color: var(--color-opensea);
        margin-right: 5px;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;
        font-size: 12px;
    }

    &-minted-count {
        font-size: 12px;
        color: rgba(255, 255, 255, 0.5);
        text-align: left;
        margin-bottom: 8px;

        span {
            color: #fff;
        }
    }

    &-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
    }

    &-action {
        padding: 8px;
        flex: 1;
        color: #fff;
        border-radius: 24px;
        font-size: 12px;
        text-decoration: none;
        transition: 0.2s;

        &-buy {
            background-color: var(--primary);

            &:hover {
                filter: brightness(0.8);
            }
        }

        &-info {
            transition: 0.2s;
            margin-left: 8px;
            flex-shrink: 0;

            &:hover {
                filter: brightness(0.8);
            }
        }
    }

    &.equipped {
        &::before {
            background: linear-gradient(to bottom,
                    var(--nft-constructor-accent),
                    transparent);
        }

        &.is-own {
            &::before {
                background: linear-gradient(to bottom,
                        var(--nft-constructor-green),
                        transparent);
            }
        }
    }

    &.is-nft {
        box-shadow: 0 0 2px 2px var(--gray-light);

        &::before,
        &::after {
            display: none;
        }
    }

    &.is-nft &-equipped {
        color: var(--green);
    }

    &.is-nft.equipped {
        box-shadow: 0 0 2px 2px var(--green);

    }
}
</style>
  