<template>
  <div class="nft-constructor-tabs">
    <div class="nft-constructor-tabs__item">
      <!-- <ApeConstructorBreadcrumbs
        :class="{ active: tab === 'traits' }"
        class="nft-constructor-tabs__categories"
        :name="activeCategory"
        @click="selectTab('traits')"
        @back="$emit('back')"
      /> -->
    </div>
    <div class="nft-constructor-tabs__item">
      <a v-sound.click href="#" :class="{ active: tab === 'my-tokens' }" class="nft-constructor-tabs__my-traits"
        @click.prevent="selectTab('my-tokens')">
        <IWallet width="18" />My Nfts
      </a>
    </div>
    <div class="nft-constructor-tabs__item">
      <a v-sound.click href="#" :class="{ active: tab === 'bg' }"
        class="nft-constructor-tabs__background nft-constructor-tabs__my-traits" @click.prevent="selectTab('bg')">
        Background
      </a>
    </div>
    <div class="nft-constructor-tabs__item">
      <a v-sound.click href="#" :class="{ active: tab === 'saved' }"
        class="nft-constructor-tabs__background nft-constructor-tabs__my-traits" @click.prevent="selectTab('saved')">
        Saved tokens
      </a>
    </div>
  </div>
</template>
<script setup>
// import ApeConstructorBreadcrumbs from "../ApeConstructorBreadcrumbs.vue";
import IWallet from "@/assets/icons/ape-constructor/ape-constructor-wallet.svg?inline";

const props = defineProps({ activeCategory: String, tab: String })
const emit = defineEmits(['update:tab'])
const selectTab = (value) => {
  emit('update:tab', value)
}
</script>
<style lang="scss">
.nft-constructor-tabs {
  display: flex;
  align-items: center;
  margin-bottom: 12px;



  &__categories {
    opacity: 0.6;
    cursor: pointer;

    &.active {
      opacity: 1;
    }

    transition: 0.2s;

    &:hover {
      opacity: 1;
    }
  }

  &__my-traits {
    color: #fff;
    text-decoration: none;
    font-size: 18px;

    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: relative;
    color: rgba(255, 255, 255, 0.6);

    svg {
      margin-right: 5px;
    }

    transition: 0.2s;

    &:hover {
      color: rgba(255, 255, 255, 1);
    }



    &.active {
      color: rgba(255, 255, 255, 1);
    }

  }

  &__background {
    margin-left: 8px;
    padding-left: 10px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 3px;
      bottom: 3px;
      width: 2px;
      background-color: #fff;
      opacity: 0.5;
    }
  }


  @media screen and (max-width: 400px) {
    flex-direction: column;
    align-items: flex-start;

    &__my-traits {
      padding-left: 0;
      margin-left: 0;
      margin-top: 8px;

      &::before {
        display: none;
      }
    }
  }
}
</style>