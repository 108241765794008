<template>
    <div class="nft-constructor-bg-gallery">
        <div class="nft-constructor-bg-gallery-items" v-if="!isLoading">
            <NftConstructorBgGalleryItem v-for="(item, idx) in items" :key="idx" :item="item" @equip="equipBg(item)" />
        </div>
        <div class="nft-constructor-bg-gallery-loading" v-else>
            <img src="@/assets/img/spinner-clear.svg" class="loader" width="50">
        </div>
        <APagination class="nft-constructor-bg-gallery-pagination" v-model:page="page" :totalItems="totalItems"
            :pageSize="pageSize" v-if="totalItems > pageSize"/>
    </div>
</template>
<script setup>
import { useApesStorageApi } from '@/api/useApesStorageApi';
import { ModalName } from '@/components/Modal/modal-name';
import { useModal } from '@/composables/useModal';
import { shorten } from '@/helpers/utils';
import { useNftConstructorStore } from '@/store/nft-constructor';
import { useWeb3Store } from '@/store/web3';
import { ref, watch } from 'vue';
import NftConstructorBgGalleryItem from './NftConstructorBgGalleryItem.vue';
import APagination from '@/components/APagination.vue';

const items = ref([])
const totalItems = ref(1)
const pageSize = ref(1)
const page = ref(1)
const isLoading = ref(false)
const fetchData = async () => {
    isLoading.value = true
    const { data, totalPages, totalItems: total, pageSize: perPage } = await useApesStorageApi({ toast: { error: true } })
        .exec({ url: "/configurator/get-backgrounds", params: { page: page.value } })
    totalItems.value = total
    pageSize.value = perPage
    if (!data) return
    items.value = data
    isLoading.value = false
}
fetchData()
watch(page, () => {
    fetchData()
})

const nftConstructor = useNftConstructorStore()
const equipBg = item => {
    const toEquip = {
        isCustom: true,
        imageSrc: item.image,
        zIndex: 1,
        categoryId: 13,
        categoryName: "Background"
    }
    nftConstructor.equipTrait(toEquip)
}

</script>
<style lang="scss">
.nft-constructor-bg-gallery {
    &-pagination {
        justify-content: center;
        margin-top: 14px;
    }

    &-items {
        display: flex;
        flex-wrap: wrap;

    }

    &-loading {
        padding-top: 20px;
        text-align: center;
    }
}
</style>