<template>
    <div class="nft-constructor-saved">
        <div class="nft-constructor-saved-items" v-if="!isLoading">
            <div class="nft-constructor-saved-item" v-for="(item, idx) in items" :key="idx">
                <img :src="item.image" alt="saved-nft" width="300" @click.prevent="openImage(item)">
                <div class="nft-constructor-saved-item-name">
                    {{ item.metadata?.tokenName }}
                </div>
            </div>
        </div>
        <div class="nft-constructor-saved-loading" v-else>
            <img src="@/assets/img/spinner-clear.svg" class="loader" width="50">
        </div>
    </div>
</template>
<script setup>
import { useApesStorageApi } from '@/api/useApesStorageApi';
import { ModalName } from '@/components/Modal/modal-name';
import { useModal } from '@/composables/useModal';
import { useWeb3Store } from '@/store/web3';
import { ref } from 'vue';
const items = ref([])
const isLoading = ref(false)
const fetchData = async () => {
    isLoading.value = true
    const data = await useApesStorageApi({ toast: { error: true } })
        .exec({ url: "/configurator/get-saved", params: { wallet: useWeb3Store().walletAddress } })
    console.log(data)
    if (!data) return
    items.value = data
    isLoading.value = false
}
fetchData()
const openImage = (item) => {
    useModal().open({ name: ModalName.ImageModal, props: { image: item.image } })
}

</script>
<style lang="scss">
.nft-constructor-saved {

    &-items {
        display: flex;
        flex-wrap: wrap;

    }

    &-item {
        margin: 9px;

        width: calc(100% / 4 - 18px);



        img {
            border-radius: 5px;
            max-width: 100%;
            height: auto;
            cursor: pointer;
        }
    }



    @media screen and (max-width: 1100px) {
        &-item {
            width: calc(100% / 3 - 18px);
        }
    }

    @media screen and (max-width: 760px) {

        &-item {
            width: calc(100% / 2 - 18px);
        }
    }

    &-loading {
        padding-top: 20px;
        text-align: center;
    }
}
</style>