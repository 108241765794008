import { useApesStorageApi } from "@/api/useApesStorageApi";
import { useNftConstructorStore } from "@/store/nft-constructor";
import { useWeb3Store } from "@/store/web3";
import { ApeConstructorCategory } from "../ape-constructor/ape-constructor-categories";
import { useToast } from "@/composables/useToast";

export const saveNftConstructorImage = async (imageBlob) => {
  const formData = new FormData();
  const nftConstructorStore = useNftConstructorStore();
  const token = nftConstructorStore.token;
  const tokenAddress = token.address;
  const tokenId = token.id || token.token_id || token.tokenId;
  const background = nftConstructorStore.equippedTraits.find(
    (trait) => trait.categoryId === ApeConstructorCategory.Background
  );
  if (!background) {
    useToast().error({ title: "Please select Background" });
    return;
  }

  const metadata = {
    wallet: useWeb3Store().walletAddress,
    tokenAddress,
    tokenId,
    backgroundId: background.id,
    background: background.name,
    tokenName: token.name
  };
  console.log(token, background, metadata)

  formData.append("image", imageBlob, "image.png");
  formData.append("metadata", JSON.stringify(metadata));
  const response = await useApesStorageApi({
    throw: true,
    toast: { error: true },
  }).exec({
    url: "/configurator/save",
    method: "POST",
    data: formData,
  });
  useToast().success({title: "Image was successfully saved!"})
};
