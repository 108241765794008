<template>
    <div class="nft-constructor-my-traits">
        <div class="nft-constructor-my-traits__items">
            <a v-for="item in items" :key="item.id" href="#" class="nft-constructor-my-traits__item"
                @click.prevent="selectItem(item)">
                <NftConstructorToken :isNft="true" :is-equipped="isEquipped(item)" :item="item" :show-category="true" />
            </a>
        </div>
        <a class="nft-constructor-my-traits__load-more" v-if="canLoadMore" href="javascript:void(0)"
            @click.prevent="loadMore">Load more
            <img src="@/assets/img/spinner-clear.svg" class="loader" width="20" v-if="loadingMore"></a>
        <APagination class="nft-constructor-my-traits__pagination" :page="page" @update:page="onSwitchPage"
            :pageSize="pageSize" :totalItems="totalItems" v-if="totalItems > 10" />
        <!-- <InfiniteLoading @infinite="loadMore" /> -->
    </div>
</template>
<script setup>
import { useNftConstructorStore } from "@/store/nft-constructor";
import { computed, ref, watch } from "vue";
import InfiniteLoading from "v3-infinite-loading";
import NftConstructorToken from "../NftConstructorToken.vue";
import APagination from "@/components/APagination.vue";

const store = useNftConstructorStore()
const isEquipped = (token) => {
    return store.token === token
}
const items = ref([])

const selectItem = (token) => {
    store.selectToken(token)
}
const page = ref(1)
const pageSize = ref(1)
const totalItems = ref(1)
const onSwitchPage = (newPage) => {
    page.value = newPage
    fetchItems()
}
const fetchItems = async () => {
    const response = await store.fetchWalletTokens({ page: page.value })
    totalItems.value = response.total
    pageSize.value = response.passedPageSize
    items.value = response.items

}
fetchItems()
const canLoadMore = computed(() => Math.ceil(totalItems.value / pageSize.value) > page.value)
const loadingMore = ref(false)
const loadMore = async () => {
    if (!canLoadMore.value) return
    loadingMore.value = true
    page.value++
    const response = await store.fetchWalletTokens({ page: page.value })
    totalItems.value = response.total
    pageSize.value = response.passedPageSize
    items.value = items.value.concat(response.items)
    loadingMore.value = false
}
</script>
<style lang="scss">
.nft-constructor-my-traits {
    padding-right: 15px;

    &__pagination {
        justify-content: center;
        margin-top: 10px;
    }

    &__sort {
        margin-bottom: 15px;
        min-width: 250px;

        .app-select-header {
            font-size: 14px;
            white-space: nowrap;
        }

        &-title {
            font-style: 12px;
            text-transform: uppercase;
            color: rgba(255, 255, 255, 0.5);
            margin-bottom: 4px;
        }
    }

    &__load-more {
        padding: 5px 10px;
        margin: 10px auto;
        margin-bottom: 20px;
        background-color: transparent;
        border: 1px solid var(--border);
        color: #fff;
        border-radius: 5px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 80%;
        text-align: center;
        text-decoration: none;
        background-color: var(--gray-light);
        img {
            margin-left: 8px;
        }
    }

    &__items {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -9px;
        overflow: auto;
    }

    &__item {
        width: calc(100% / 5 - 18px);
        margin: 14px 9px;
        box-sizing: border-box;
        text-decoration: none;
    }

    @media screen and (max-width: 1880px) {
        &__item {
            width: calc(100% / 4 - 18px);
        }
    }

    @media screen and (max-width: 1100px) {
        &__item {
            width: calc(100% / 3 - 18px);
        }
    }

    @media screen and (max-width: 760px) {
        padding-right: 0px;

        &__item {
            width: calc(100% / 2 - 18px);
        }
    }
}
</style>