<template>
    <div class="nft-constructor-bg-gallery-item">
        <img :src="item.image" alt="saved-nft" width="300" @click.prevent="openImage(item)">
        <div class="nft-constructor-bg-gallery-item-name">
            {{ item.name }}
        </div>
        <div class="nft-constructor-bg-gallery-item-rating"
            :class="{ loading: rateLoading, up: rating > 0, down: rating < 0 }">
            <a href="javascript:void(0)" @click.prevent="rateItem('upvote')"
                class="nft-constructor-bg-gallery-item-rating-action" :class="{ active: myVote > 0 }">
                <IUp width="16" />
            </a>
            <div class="nft-constructor-bg-gallery-item-rating-value">{{ rating }}</div>
            <a href="javascript:void(0)" @click.prevent="rateItem('downvote')"
                class="nft-constructor-bg-gallery-item-rating-action" :class="{ active: myVote < 0 }">
                <IDown width="16" />
            </a>
        </div>
        <div class="nft-constructor-bg-gallery-item-prop">
            <div class="nft-constructor-bg-gallery-item-prop-name">
                Price
            </div>
            <div class="nft-constructor-bg-gallery-item-prop-value">
                {{ item.price }} {{ item.paymentTokenName ? ' ' + item.paymentTokenName : '' }}
            </div>
        </div>
        <div class="nft-constructor-bg-gallery-item-prop">
            <div class="nft-constructor-bg-gallery-item-prop-name">
                Payment Token
            </div>
            <div class="nft-constructor-bg-gallery-item-prop-value">
                <a target="_blank" :href="'https://etherscan.io/address/' + item.paymentToken">{{
                    shorten(item.paymentToken, 4,
                        4) }}</a>
            </div>
        </div>
        <div class="nft-constructor-bg-gallery-item-prop">
            <div class="nft-constructor-bg-gallery-item-prop-name">
                Supply
            </div>
            <div class="nft-constructor-bg-gallery-item-prop-value">
                {{ item.supply }}
            </div>
        </div>

        <div class="nft-constructor-bg-gallery-item-prop">
            <div class="nft-constructor-bg-gallery-item-prop-name">
                Creator
            </div>
            <div class="nft-constructor-bg-gallery-item-prop-value">
                <a target="_blank" :href="'https://etherscan.io/address/' + item.wallet">{{ item.creatorName ||
                    shorten(item.wallet, 4, 4)
                }}</a>
            </div>
        </div>
        <a href="#" class="nft-constructor-bg-gallery-item-equip" @click.prevent="$emit('equip')">Try</a>
    </div>
</template>
<script setup>
import { useApesStorageApi } from '@/api/useApesStorageApi';
import { ModalName } from '@/components/Modal/modal-name';
import { useModal } from '@/composables/useModal';
import { useToast } from '@/composables/useToast';
import { shorten } from '@/helpers/utils';
import { useWeb3Store } from '@/store/web3';
import { computed, ref } from 'vue';

import IUp from "@/assets/img/chevron-up.svg?inline"
import IDown from "@/assets/icons/chevron-down.svg?inline"
const props = defineProps({ item: Object })
defineEmits(['equip'])

const openImage = (item) => {
    useModal().open({ name: ModalName.ImageModal, props: { image: item.image } })
}
const cachedVotes = ref()
const votes = computed(() => cachedVotes.value || props.item.votes)
const upvotesCount = computed(() => votes.value.upvotes.length)
const downvotesCount = computed(() => votes.value.downvotes.length)
const rating = computed(() => upvotesCount.value - downvotesCount.value)
const myVote = computed(() => {
    const currentVotes = votes.value
    const hasUp = currentVotes.upvotes.findIndex(w => w.toLowerCase() === useWeb3Store().walletAddress.toLowerCase()) > -1
    if (hasUp) return 1
    const hasDown = currentVotes.downvotes.findIndex(w => w.toLowerCase() === useWeb3Store().walletAddress.toLowerCase()) > -1
    if (hasDown) return -1
    return 0
})
const rateLoading = ref(false)
const rateItem = async (value) => {
    rateLoading.value = true
    const res = await useApesStorageApi({ toast: { error: true } }).exec({
        url: "/configurator/vote",
        method: "POST",
        data: {
            "voteId": props.item._id, // _id of the background
            "wallet": useWeb3Store().walletAddress,
            "voteType": value // "downvote" or "upvote"
        }
    })
    cachedVotes.value = res.result


    // useToast().success({ title: res.message })
    rateLoading.value = false

}
</script>
<style lang="scss">
.nft-constructor-bg-gallery-item {

    margin: 9px;
    width: calc(100% / 4 - 18px);

    img {
        border-radius: 5px;
        max-width: 100%;
        height: auto;
        cursor: pointer;
    }

    &-name {
        text-align: center;
        margin-bottom: 5px;
    }

    &-rating {
        display: flex;
        align-items: center;
        justify-content: center;

        &-value {
            margin: 0 8px;
            font-size: 20px;
        }

        &-action {
            padding: 0 5px;
            height: 25px;
            border: 1px solid vaR(--border);
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            text-decoration: none;
            transition: .2s;

            &:hover {
                background-color: var(--gray-light);
            }

            &.active {
                background-color: var(--primary);
            }
        }

        &.up {
            color: var(--green);
        }

        &.down {
            color: var(--red);

        }

        &.loading {
            opacity: 0.5;
            pointer-events: none;
        }
    }

    &-prop {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        border-bottom: 1px solid var(--border);
        font-size: 14px;
        padding: 3px 0;

        &:last-child {
            border-bottom: 0;
        }

        &-name {
            text-transform: uppercase;
            opacity: 0.5;
            margin-right: 5px;
        }


        &-value {
            overflow: hidden;
            text-overflow: ellipsis;

            a {
                color: var(--primary);
            }
        }
    }

    &-equip {
        border: 1px solid var(--border);
        background: var(--gray-light);
        padding: 5px 10px;
        color: #fff;
        text-decoration: none;
        width: 100%;
        box-sizing: border-box;
        margin-top: 5px;
        display: block;
        text-align: center;
        border-radius: 5px;
        transition: .3s;

        &:hover {
            background-color: rgba(255, 2552, 255, 0.3);
        }
    }



    @media screen and (max-width: 1880px) {

        width: calc(100% / 3 - 18px);

    }

    @media screen and (max-width: 760px) {


        width: calc(100% / 2 - 18px);

    }
}
</style>