<template>
  <div class="nft-constructor-community">
    <a href="#" @click.prevent="createBg" class="nft-constructor-community-action">
      <svgPlus width="16" />Create Background
    </a>
    <a href="#" @click.prevent="openBackgrounds" :class="{ active: tab === 'bg-gallery' }"
      class="nft-constructor-community-action">
      <IGrid width="16" />Backgrounds Gallery
    </a>
    <!-- <a href="#" @click.prevent="openCommunity" :class="{ active: tab === 'community' }"
      class="nft-constructor-community-action">
      <IUser width="16" />Community
    </a> -->
  </div>
</template>
<script setup>
import { ModalName } from '@/components/Modal/modal-name';
import { useModal } from '@/composables/useModal';
import svgPlus from "@/assets/icons/plus-stroke.svg?inline"
import IGrid from "@/assets/icons/grid-2.svg?inline"
import IUser from "@/assets/icons/user.svg?inline"
const props = defineProps({ tab: String })
const emit = defineEmits(['update:tab'])

const openBackgrounds = () => {
  emit('update:tab', 'bg-gallery')
}
const openCommunity = () => {
  emit('update:tab', 'community')
}
const createBg = () => {
  useModal().open({ name: ModalName.CreateConstructorBackgroundModal })
}
</script>
<style lang="scss">
.nft-constructor {
  &-community {
    display: flex;
    align-items: center;
    flex-wrap: wrap;


    &-action {
      display: inline-flex;
      text-decoration: none;
      border: 1px solid var(--border);
      padding: 10px 20px;
      border-radius: 5px;
      color: #fff;
      text-transform: uppercase;
      background-color: var(--gray-light);
      margin-bottom: 15px;
      margin-right: 8px;

      &:last-child {
        margin-right: 0;
      }

      svg {
        margin-right: 5px;
      }

      transition: .2s;

      &:hover {}

      &.active {
        background-color: var(--primary);
      }
    }
  }




}
</style>