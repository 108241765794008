import { useApesStorageApi } from "@/api/useApesStorageApi";
import { defineStore } from "pinia";
import { useWeb3Store } from "./web3";
import mitt from "mitt";
import qs from "qs";
import { Config } from "@/config";
import { useApiGetApeTraitImages } from "@/api/apes/get-trait-images";

export const useNftConstructorStore = defineStore("nft-constructor", {
  state: () => ({
    token: {},
    bodyTypeId: 1,
    genderId: 1,
    walletTokens: [],
    equippedTraits: [],
    ee: mitt(),
    traitsImages: {},
    bgStrength: 0
    // noBgCache: {},
  }),
  getters: {
    tokenImageCacheId() {
      const address = this.token.address;
      const tokenId = this.token.token_id;
      return `${address}-${tokenId}`;
    },
    noBgImage() {
      if (!this.token || !Object.keys(this.token).length) return;
      const query = qs.stringify({
        address: this.token.address,
        tokenId: this.token.token_id,
        wallet: useWeb3Store().walletAddress,
        script: this.bgStrength
      });
      return `${Config.apeStorageUrl}/metadata/image-without-background?${query}`;
    },
    equippedTraitsImages() {
      const traits = this.equippedTraits.map((trait) => ({
        ...this.traitsImages[this.getTraitImageCacheKey(trait)],
        zIndex: 1,
      }));
      const toRender = [...traits];
      if (this.noBgImage) {
        toRender.push({ imageSrc: this.noBgImage, zIndex: 5 });
      }
      console.log(toRender)
      return toRender;
    },
    getTraitImageCacheKey() {
      return (trait) => {
        let cacheKey = `${trait.id}`;
        return cacheKey;
      };
    },
  },
  actions: {
    changeBgStrength(bgStrength) {
      this.bgStrength = bgStrength
      this.ee.emit("render");

    },
    selectToken(token) {
      this.token = token;
      this.ee.emit("render");
    },
    // async getNoBgImage(token) {
    //   if (this.noBgCache[this.tokenImageCacheId])
    //     return this.noBgCache[this.tokenImageCacheId];

    //   const result = await useApesStorageApi({ throw: true }).exec({
    //     url: "/metadata/image-without-background",
    //     params: {},
    //   });
    //   this.noBgCache[cacheId] = result;
    //   return result;
    // },
    async fetchTraitImages(trait) {
      const traitId = trait.id;
      let cacheKey = this.getTraitImageCacheKey(trait);
      if(trait.isCustom) {
        this.traitsImages[cacheKey] = trait
        return
      }
      if (!cacheKey) return;

      if (this.traitsImages[cacheKey]) return this.traitsImages[cacheKey];
      const images = await useApiGetApeTraitImages({
        throw: true,
      }).exec({
        bodyTypeId: this.bodyTypeId,
        genderId: this.genderId,
        traitId,
      });
      this.traitsImages[cacheKey] = images[0];
    },
    async equipTrait(trait) {
      this.equippedTraits = [trait];
      await this.fetchTraitImages(trait);
      this.ee.emit("render");
    },
    async fetchWalletTokens({ page } = { page: 1 }) {
      const response = await useApesStorageApi({ throw: true }).exec({
        url: "/metadata/all-tokens",
        params: {
          wallet: useWeb3Store().walletAddress,
          currentPage: page,
        },
      });

      const items = response.items.map((item) => ({
        address: item.address,
        token_id: item.token_id,
        ...item.metadata,
      }));
      return { ...response, items };
    },
  },
});
