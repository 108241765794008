<template>
  <div class="nft-constructor-traits-wrapper">
    <div class="nft-constructor-traits">
      <a v-for="item in traits" :key="item.id" href="#" class="nft-constructor-traits__item"
        @click.prevent="selectItem(item)">
        <ApeConstructorTraitCard :is-equipped="item.id === activeItem" :item="item" :reduce-top="true"
          @openInfo="$emit('openInfo', item)" />
      </a>

      <div v-if="isLoading" class="nft-constructor-traits-loading">
        <img src="@/assets/img/spinner-clear.svg" class="spinner" width="60">
      </div>
      <InfiniteLoading @infinite="loadMore" />
    </div>
  </div>
</template>
<script>
import { useApeConstructorStore } from "@/store/ape-constructor";
import { computed, defineComponent, onMounted, ref, watch } from "vue-demi";
import ApeConstructorTraitCard from "@/components/ApeConstructor/ApeConstructorTraitCard/ApeConstructorTraitCard.vue";

import InfiniteLoading from "v3-infinite-loading";
import IArrowLeft from "@/assets/icons/arrow-left-long.svg?inline"
import { useNftConstructorStore } from "@/store/nft-constructor";
import { useApiGetApeTraits } from "@/api/apes/get-traits";
import { pageview } from "vue-gtag";
export default defineComponent({
  components: { InfiniteLoading, IArrowLeft, ApeConstructorTraitCard, },
  props: {
    categoryId: Number,
  },
  setup(props, { emit }) {
    const store = useNftConstructorStore()
    const items = ref([])
    const page = ref(1)
    const canLoadMore = ref(true)
    const isLoading = ref(false)
    const fetchItems = async () => {
      isLoading.value = true
      const traitsData = await useApiGetApeTraits({ throw: true }).exec({
        categoryId: props.categoryId,
        genderId: 1,
        bodyTypeId: 1,
        page: page.value,
      });
      let newItems = [...items.value, ...traitsData.traits]
      newItems = newItems.filter((item, index) => newItems.findIndex(t => t.id == item.id) === index)
      items.value = newItems
      if (!traitsData.traits.length) {
        canLoadMore.value = false
      }
      isLoading.value = false
    }
    const traits = computed(() => {
      return items.value
    })
    const loadMore = async () => {
      if (!canLoadMore.value) return
      page.value++
      fetchItems()
    }
    fetchItems()
    watch(() => props.categoryId, () => {
      fetchItems()
    })
    const selectItem = (item) => {
      store.equipTrait(item)
    }
    const activeItem = computed(() => {
      const trait = store.equippedTraits.find(item => item.categoryId === props.categoryId)
      return trait?.id
    })



    return { selectItem, isLoading, traits, loadMore, fetchItems, items, activeItem, page }
  }
})
</script>
<style lang="scss">
.nft-constructor-traits {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -9px;
  overflow: auto;
  /*height: 500px; */
  padding-right: 15px;

  &__item {
    width: calc(100% / 5 - 18px);
    margin: 9px;
    box-sizing: border-box;
    text-decoration: none;
  }

  &-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 5px;
    border: 1px solid var(--border);
    background-color: transparent;
    font-family: inherit;
    margin-bottom: 16px;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background-color: rgba(255, 255, 255, 0.3);
    }

    svg {
      margin-right: 5px;
    }
  }

  &-loading {
    display: flex;
    justify-content: center;
    padding: 30px;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 1880px) {
    &__item {
      width: calc(100% / 4 - 18px);
    }
  }

  @media screen and (max-width: 1100px) {
    &__item {
      width: calc(100% / 3 - 18px);
    }
  }

  @media screen and (max-width: 760px) {
    padding-right: 0px;

    &__item {
      width: calc(100% / 2 - 18px);
    }

    &-button {
      font-size: 14px;
    }
  }
}
</style>
